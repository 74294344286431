import React from "react"
import { graphql } from 'gatsby'
import LocaleContext from '../../contexts/LocaleContext'
import Layout from '../../components/Layout'
import PortfolioLayout from '../../components/PortfolioLayout'
import Menu from '../../components/Menu'
import Footer from '../../components/Footer'
import ShorterContent from '../../components/ShorterContent'

export const query = graphql`
  query ($locale: String) {
    datoCmsPortfolio ( locale: { eq: $locale}) {
      assetValue
      assetValueDevelopment
    }
    datoCmsNewMenu ( locale: { eq: $locale}) {
      ...Menu
    }
    datoCmsFooter ( locale: { eq: $locale}) {
      ...Footer
    }
  }
  `

export default function Portfolio({ data, pageContext }) {
  const { assetValue, assetValueDevelopment } = data.datoCmsPortfolio

  return (

    <LocaleContext.Provider value={pageContext.locale || 'en'}>
      <Layout transparentMenu locale={pageContext.locale}>
      <Menu
          transparent={true}
          menu={data.datoCmsNewMenu}
          socialMedia={data.datoCmsFooter.socialMedia}
        />
          <PortfolioLayout>
          <section className="container center-width prose">
            <ShorterContent>
                <div dangerouslySetInnerHTML={{
                    __html: assetValue,
                }}></div>
                <div dangerouslySetInnerHTML={{
                    __html: assetValueDevelopment,
                }}></div>
            </ShorterContent>
            
          </section>

        </PortfolioLayout>
        <Footer footer={data.datoCmsFooter} />
      </Layout>
    </LocaleContext.Provider>
  )
}
